import React from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Button,
} from '@material-ui/core';
import {
  ContractProductViewModel,
} from '../../api/axcloud';
import {
  UpgradeProductDialog,
} from './UpgradeProductDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
    },
    versionCell: {
      width: "15%",
    },
    actionCell: {
      width: "20%",
    }
  }),
);

export interface ProductsTemplateProps {
  versions: ContractProductViewModel[];

  upgradingProduct: ContractProductViewModel | null;
  onOpenUpgrade: (product: ContractProductViewModel) => void;
  onCancelUpgrade: () => void;
  onSubmitUpgrade: (product: ContractProductViewModel, version: string) => void;
} 

export function ProductsTemplate(props: ProductsTemplateProps) {
  const classes = useStyles({});

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h5" component="h3">
          利用製品
        </Typography>
        <Table>
          <caption>
            <a
              href={process.env.REACT_APP_DOWNLOAD_SITE_URI}
              target="_blank"
              rel="noopener noreferrer"
            >
              セットアップのダウンロードはこちら
            </a>
          </caption>

          <TableHead>
            <TableRow>
              <TableCell>製品名</TableCell>
              <TableCell className={classes.versionCell}>バージョン</TableCell>
              <TableCell className={classes.actionCell}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.versions.map(x => (
              <TableRow key={x.productId!}>
                <TableCell>{x.productName}</TableCell>
                <TableCell>{x.version}</TableCell>
                <TableCell>
                  {/*TODO: 販売AXクラウドv1.5を公開したらコメントアウト解除する
                  x.canUpgrade && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => props.onOpenUpgrade(x)}
                    >
                      変更
                    </Button>
                  )*/}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <UpgradeProductDialog
        product={props.upgradingProduct}
        onCancel={props.onCancelUpgrade}
        onSubmit={props.onSubmitUpgrade}
      />
    </>
  );
}
